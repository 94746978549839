import React, { useState, useEffect, useRef }  from 'react'
import Left_menu from './Left_menu'

import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import Header from './Header';
import { useNetworkState } from 'react-use';


import Chart from 'chart.js/auto';
import Footer from '../Footer';
import moment from 'moment';


const New_dashboard = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const chartRef = useRef(null);
    const [shippingstate,setshippingState] = React.useState([])
    console.log("shippingstate == " , shippingstate)
    const[balancestate,setbalanceState]= React.useState({total_balance:[]})
    const [selectedRange, setSelectedRange] = React.useState([new Date(), new Date()]);
    const [financedate, setfinancedate] = React.useState({
      finance_start_date: formatDate(new Date()),
      finance_end_date: formatDate(new Date())
    });
    const [statusBookingData, setStatusBookingData] = useState([]);
    const[financeloader , setfinanceloader] = React.useState(false)
    const [sevendayrecharges,setSevenDayRecharges] = React.useState({last7DaysRecharges:[] ,totalRechargesLast7Days:0 , totalRechargesToday:0 });
    function formatDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
    console.log("financedate == " , financedate)

    // const handleDateRangeChange = (range) => {
    //   setSelectedRange(range);
    //   console.log('Selected range:', range);
    // };
    const handleStartDateChange = (event) => {
      const newStartDate = event.target.value;
      setSelectedRange([new Date(newStartDate), selectedRange[1]]);
    };
  
    const handleEndDateChange = (event) => {
      const newEndDate = event.target.value;
      setSelectedRange([selectedRange[0], new Date(newEndDate)]);
    };
  const [state, setstate] = React.useState({ today_shipments:0 , yesterday_shipments:0  , today_orders:0 , yesterday_orders:0 , total_shipments:0 , pickup_shipments:0 , transit_shipments:0 , delivered_shipments:0 , today_revenue:0 , yesterday_revenue:0});
  const [shipstate, setshipstate] = React.useState({
    today_shipments: 0,
    today_failed: 0,
    pickup_pending: 0,
    today_in_transit:0,
    delivered_shipments:0,
    ndr_shipments:0,
    rto_shipments:0,
    month_shipments: 0,
    total_shipments: 0,
    today_channel_orders: 0,
    order_summary: [],
    courier_overview: [],
    zone_overview: [],
    zone_total_overview:{},

    total_overview:{},
    performanceOverview:[],
    today_shipments_stats:0,
    today_failed_stats:0,
    today_in_transit_stats:0,
    today_delivered_shipments_stats:0,
    today_ndr_shipments_stats:0,
    today_rto_shipments_stats:0,
    today_pickup_pending_stats:0,
    today_total_shipments_stats:0,
    today_channel_orders_stats:0,
    today_picked_up:0
  });
  const[sevendaystate , setsevendaystate]= React.useState({last7DaysShipments: [] , totalShipmentsCount:0})
  console.log("sevendaystate == " , sevendaystate)
  const [topCities, setTopCities] = useState([]);
  const [topStates, setTopStates] = useState([]);
  const [filterType, setFilterType] = React.useState('all');
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  // Format the start date and end date as strings
  const formattedStartDate = startDate.toISOString().split('T')[0];
  const formattedEndDate = new Date().toISOString().split('T')[0];
const[date,setdate]=React.useState({start_date:formattedStartDate , end_date:formattedEndDate})

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  React.useEffect(()=>{
   
    get_wallet_balance()
    get_full_dashboard_data()
    list()
    // get_dashboard_data()
    fetchDashboardData()
  
  },[])
  // React.useEffect(()=>{
  //   get_dashboard_data()
  // },[date])

    const get_wallet_balance=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/get_customer_wallet';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
           setbalanceState({total_balance:res.data.find_customer_wallet})
        }
       else{
          //  const Toast = Swal.mixin({
          //      toast: true,
          //      position: 'bottom-end',
          //      showConfirmButton: false,
          //      timer: 3000
          //    })
          //    Toast.fire({
          //      background:"rgb(231, 91, 75)",
          //      type: 'Unsuccess',
          //      title: "Something Went Wrong !",
          //      color:"white"
          //    });
       } })
           
             .catch((error) => {
              //  const Toast = Swal.mixin({
              //      toast: true,
              //      position: 'bottom-end',
              //      showConfirmButton: false,
              //      timer: 3000
              //    })
              //    Toast.fire({
              //      background:"rgb(231, 91, 75)",
              //      type: 'Unsuccess',
              //      title: "Something Went Wrong !",
              //      color:"white"
              //    });
                 // console.log(error);
             });    
       
       }

    
      
     
      const fetchDashboardData = async () => {
        const today = moment().format('YYYY-MM-DD');
        console.log("today date === ", today);
        const sevenDaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');
        console.log("seven days ago date === ", sevenDaysAgo);
   try {
          const url = `${config.apiUrl}/home/graph_dashboard_data`;
          const dataToSend = {
            customer_id: userData.customer_id,
            date: [sevenDaysAgo, today],
          };
  
          const response = await axios.post(url, dataToSend, { headers: config.headers });
          console.log("repone === " , response)
  
          if (response.data.status === true) {
            const shipments = response.data.output;
            const transactions = response.data.transactions
  
            // Calculate shipments count for each day in the last 7 days
            const last7DaysShipments = [];
            let totalShipmentsCount = 0
            for (let i = 6; i >= 0; i--) {
              const date = moment(today).subtract(i, 'days').format('YYYY-MM-DD');
              const count = shipments.filter(shipment => shipment.shipment_created_date === date && shipment.status !== 0 ).length;
              last7DaysShipments.push({
                date: date,
                count: count,
              });
              totalShipmentsCount += count;
            }
            setsevendaystate({last7DaysShipments:last7DaysShipments ,totalShipmentsCount:totalShipmentsCount })
            const last7DaysShipmentsStatus = [];

            for (let i = 6; i >= 0; i--) {
              const date = moment(today).subtract(i, 'days').format('YYYY-MM-DD');
              const shipmentsForDate = shipments.filter(shipment => (shipment.shipment_created_date) === date);
              last7DaysShipmentsStatus.push({
                date: date,
                shipments: shipmentsForDate,
              });
            }
        
            const statusCounts = {
              "Pick Up Awaited": 0,
              "Picked Up": 0,
              "In Transit": 0,
              "Out For Delivery": 0,
              "Delivered": 0,
              "Cancelled": 0,
              "Failed": 0,
              "NDR": 0,
              "Pickup Scheduled": 0,
              "RTO": 0,
              "Booked":0
            };
        
            last7DaysShipmentsStatus.forEach(day => {
              day.shipments.forEach(shipment => {
                switch (shipment.status) {
                  case 15:
                    statusCounts["Booked"]++;
                    break;
                  case 1:
                    statusCounts["Pick Up Awaited"]++;
                    break;
                  case 2:
                    statusCounts["Picked Up"]++;
                    break;
                  case 3:
                    statusCounts["In Transit"]++;
                    break;
                  case 4:
                    statusCounts["Out For Delivery"]++;
                    break;
                  case 5:
                    statusCounts["Delivered"]++;
                    break;
                  case 6:
                    statusCounts["Cancelled"]++;
                    break;
                  case 7:
                    statusCounts["Failed"]++;
                    break;
                  case 8:
                    statusCounts["NDR"]++;
                    break;
                  case 9:
                    statusCounts["Pickup Scheduled"]++;
                    break;
                  case 10:
                    statusCounts["RTO"]++;
                    break;
                  default:
                    break;
                }
              });
            });
        
            const data = Object.keys(statusCounts).map(status => ({
              status: status,
              totalCount: statusCounts[status]
            }));
        
            setStatusBookingData(data);
            const last7DaysRecharges = [];
            let totalRechargesLast7Days = 0;
            let totalRechargesToday = 0;
            for (let i = 6; i >= 0; i--) {
              const date = moment(today).subtract(i, 'days').format('YYYY-MM-DD');
              const rechargesForDate = transactions.filter(transaction => moment.unix(transaction.created).format('YYYY-MM-DD') === date);
              const totalAmount = rechargesForDate.reduce((sum, transaction) => sum + transaction.amount, 0);
              last7DaysRecharges.push({
                date: date,
                amount: totalAmount,
              });
              totalRechargesLast7Days += totalAmount;
              if (date === today) {
                totalRechargesToday = totalAmount;
              }
            }
            setSevenDayRecharges({last7DaysRecharges:last7DaysRecharges ,totalRechargesLast7Days:totalRechargesLast7Days , totalRechargesToday:totalRechargesToday });
           
     
          } else {
            console.error('Error fetching dashboard data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching dashboard data:', error);
          // Handle error
        }
      };
     
              const get_full_dashboard_data=()=>{
                let dataToSend = {customer_id : userData.customer_id , date:selectedRange};
               
                 // console.log("datatoSend",dataToSend)
                 let url = config.apiUrl + '/home/all_dashboard_data';
                 axios.post(url, dataToSend, { headers: config.headers })
                     .then((res) => {
                     console.log("responseJson => ", res);
                     if(res.data.status==true){
                        const shipments = res.data.output;
                        
                        const today = new Date().toISOString().split('T')[0];
                        const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0];
              
                        const today_shipments = shipments.filter(shipment => shipment.status !== 0 && shipment.status !== 7).length;
                        const today_failed = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 1).length;
                        const today_in_transit = shipments.filter(shipment =>  shipment.status === 3).length;
                        const delivered_shipments = shipments.filter(shipment =>  shipment.status === 5).length;
                        const ndr_shipments = shipments.filter(shipment =>  shipment.status === 8).length;
                        const rto_shipments = shipments.filter(shipment =>  shipment.status === 10).length;


                        const pickup_pending = shipments.filter(shipment => shipment.status === 1).length;
                        const month_shipments = shipments.filter(shipment => shipment.shipment_created_date >= startOfMonth && shipment.status !== 0 && shipment.status !== 7).length;
                        const total_shipments = shipments.filter(shipment => shipment.status !== 0 && shipment.status !== 7).length;
                        const today_channel_orders = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.store_id !== "" && shipment.status !== 0 && shipment.status !== 7).length;

                        const today_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today &&shipment.status !== 0 && shipment.status !== 7).length;
                        const today_failed_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 1).length;
                        const today_in_transit_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 3).length;
                        const today_delivered_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 5).length;
                        const today_ndr_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 8).length;
                        const today_rto_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 10).length;


                        const today_pickup_pending_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 1).length;
                       
                        const today_total_shipments_stats = shipments.filter(shipment => shipment.status !== 0 && shipment.status !== 7).length;
                        const today_channel_orders_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.store_id !== "" && shipment.status !== 0 && shipment.status !== 7).length;
                        const today_picked_up  = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.store_id !== "" && shipment.status == 2 ).length;

                        const orderSummary = shipments.reduce((acc, shipment) => {
                            console.log("acc ==" , acc)
                            const carrierName = shipment.carrier_name;
                            if (shipment.status === 0 ||shipment.status === 7 ) {
                              return acc;
                            }
                            if (!acc[carrierName]) {
                              acc[carrierName] = { cod: 0, prepaid: 0, total: 0 };
                            }
                            acc[carrierName].total++;
                            if (shipment.payment_mode === 'cod') {
                              acc[carrierName].cod += shipment.cod_amount;
                            }
                            return acc;
                          }, {});
                
                          const orderSummaryArray = Object.keys(orderSummary).map((key, index) => ({
                            

                            sno: index + 1,
                            carrier: key,
                            cod: orderSummary[key].cod,
                            prepaid: 0,
                            total: orderSummary[key].total
                          })
                        );

                          const courierOverview = shipments.reduce((acc, shipment) => {
                            const carrierName = shipment.carrier_name;
                            if (shipment.status === 0 ||shipment.status === 7 ) {
                              return acc;
                            }
                            if (!acc[carrierName]) {
                              acc[carrierName] = { booked: 0, delivered: 0, pending: 0, rto: 0 };
                            }
                            acc[carrierName].booked++;
                            if (shipment.status === 5) {
                              acc[carrierName].delivered++;
                            } else if (shipment.status === 8) {
                              acc[carrierName].rto++;
                            } else {
                              acc[carrierName].pending++;
                            }
                            return acc;
                          }, {});
                
                          const courierOverviewArray = Object.keys(courierOverview).map((key, index) => ({
                            carrier: key,
                            booked: courierOverview[key].booked,
                            delivered: courierOverview[key].delivered,
                            deliveredPercentage: ((courierOverview[key].delivered / courierOverview[key].booked) * 100).toFixed(2),
                            pending: courierOverview[key].pending,
                            pendingPercentage: ((courierOverview[key].pending / courierOverview[key].booked) * 100).toFixed(2),
                            rto: courierOverview[key].rto,
                            rtoPercentage: ((courierOverview[key].rto / courierOverview[key].booked) * 100).toFixed(2),
                          }));
                
                          const totalOverview = courierOverviewArray.reduce((acc, curr) => {
                            acc.booked += curr.booked;
                            acc.delivered += curr.delivered;
                            acc.pending += curr.pending;
                            acc.rto += curr.rto;
                            return acc;
                          }, { booked: 0, delivered: 0, pending: 0, rto: 0 });
                
                          totalOverview.deliveredPercentage = ((totalOverview.delivered / totalOverview.booked) * 100).toFixed(2);
                          totalOverview.pendingPercentage = ((totalOverview.pending / totalOverview.booked) * 100).toFixed(2);
                          totalOverview.rtoPercentage = ((totalOverview.rto / totalOverview.booked) * 100).toFixed(2);

                          const zoneOverview = shipments.reduce((acc, shipment) => {
                            const carrierName = shipment.zone_name;
                            if (shipment.status === 0 ||shipment.status === 7 ) {
                              return acc;
                            }
                            if (!acc[carrierName]) {
                              acc[carrierName] = { booked: 0, delivered: 0, pending: 0, rto: 0 };
                            }
                            acc[carrierName].booked++;
                            if (shipment.status === 5) {
                              acc[carrierName].delivered++;
                            } else if (shipment.status === 8) {
                              acc[carrierName].rto++;
                            } else {
                              acc[carrierName].pending++;
                            }
                            return acc;
                          }, {});
                
                          const zoneOverviewArray = Object.keys(zoneOverview).map((key, index) => ({
                            zone_name: key,
                            booked: zoneOverview[key].booked,
                            delivered: zoneOverview[key].delivered,
                            deliveredPercentage: ((zoneOverview[key].delivered / zoneOverview[key].booked) * 100).toFixed(2),
                            pending: zoneOverview[key].pending,
                            pendingPercentage: ((zoneOverview[key].pending / zoneOverview[key].booked) * 100).toFixed(2),
                            rto: zoneOverview[key].rto,
                            rtoPercentage: ((zoneOverview[key].rto / zoneOverview[key].booked) * 100).toFixed(2),
                          }));
                
                          const zone_totalOverview = zoneOverviewArray.reduce((acc, curr) => {
                            acc.booked += curr.booked;
                            acc.delivered += curr.delivered;
                            acc.pending += curr.pending;
                            acc.rto += curr.rto;
                            return acc;
                          }, { booked: 0, delivered: 0, pending: 0, rto: 0 });
                
                          zone_totalOverview.deliveredPercentage = ((zone_totalOverview.delivered / zone_totalOverview.booked) * 100).toFixed(2);
                          zone_totalOverview.pendingPercentage = ((zone_totalOverview.pending / zone_totalOverview.booked) * 100).toFixed(2);
                          zone_totalOverview.rtoPercentage = ((zone_totalOverview.rto / zone_totalOverview.booked) * 100).toFixed(2);





                          const performanceOverviewData = calculatePerformanceOverview(shipments , selectedRange);
                         
                          const { cityCounts, stateCounts } = countShipmentsByLocation(shipments);
                          const totalShipments = shipments.length;
                          setTopCities(getTopLocations(cityCounts , totalShipments));
                          setTopStates(getTopLocations(stateCounts , totalShipments));
                
              
                        setshipstate({
                          today_shipments,
                          today_failed,
                          pickup_pending,
                          today_in_transit,
                          delivered_shipments,
                          ndr_shipments,
                          rto_shipments,
                          month_shipments,
                          total_shipments,
                          today_channel_orders,
                          order_summary: orderSummaryArray,
                          courier_overview: courierOverviewArray,
            total_overview: totalOverview,
            zone_overview: zoneOverviewArray,
            zone_total_overview: zone_totalOverview,
            performanceOverview:performanceOverviewData,
            today_shipments_stats,
            today_failed_stats,
            today_in_transit_stats,
            today_delivered_shipments_stats,
            today_ndr_shipments_stats,
            today_rto_shipments_stats,
            today_pickup_pending_stats,
            today_total_shipments_stats,
            today_channel_orders_stats,
            today_picked_up

                        });
                     }
                      
             
                })
                   
                     .catch((error) => {
                      //  const Toast = Swal.mixin({
                      //      toast: true,
                      //      position: 'bottom-end',
                      //      showConfirmButton: false,
                      //      timer: 3000
                      //    })
                      //    Toast.fire({
                      //      background:"rgb(231, 91, 75)",
                      //      type: 'Unsuccess',
                      //      title: "Something Went Wrong !",
                      //      color:"white"
                      //    });
                         // console.log(error);
                     });    
               
               }

               const handleFilterChange = (e) => {
                setFilterType(e.target.value);
              };
            
              const filteredCourierOverview = shipstate.courier_overview.filter(shipment => {
                if (filterType === 'cod') {
                  return shipment.payment_mode === 'cod';
                } else if (filterType === 'prepaid') {
                  return shipment.payment_mode === 'prepaid';
                }
                return true; // 'all' or any other case
              });
            
              const calculatePerformanceOverview = (shipments, dateRange) => {
                const datesInRange = getDatesInRange(dateRange);
                const performanceData = datesInRange.map(date => {
                  const shipmentsOnDate = shipments.filter(shipment => shipment.shipment_created_date === date && shipment.status != 0 && shipment.status != 7);
                  const booked = shipmentsOnDate.length;
                  const delivered = shipmentsOnDate.filter(shipment => shipment.status === 5).length;
                  const pending = shipmentsOnDate.filter(shipment => shipment.status !== 5 && shipment.status !== 8).length;
                  const rto = shipmentsOnDate.filter(shipment => shipment.status === 8).length;
                  return {
                    date,
                    booked,
                    delivered,
                    deliveredPercentage: delivered!=0? ((delivered / booked) * 100).toFixed(2):"0.00",
                    pending,
                    pendingPercentage: pending!=0?((pending / booked) * 100).toFixed(2):'0.00',
                    rto,
                    rtoPercentage: rto!=0?((rto / booked) * 100).toFixed(2):'0.00',
                  };
                });
              
                return performanceData;
              };
            
              const getDatesInRange = (dateRange) => {
                const [startDate, endDate] = dateRange;
                const start = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
                const end = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));
              
                const dates = [];
                let currentDate = start;
              
                while (currentDate <= end) {
                  dates.push(new Date(currentDate).toISOString().split('T')[0]);
                  currentDate.setUTCDate(currentDate.getUTCDate() + 1);
                }
              
                return dates;
              };
            
              const countTotalDays = (dateRange) => {
                const [startDate, endDate] = dateRange;
                const start = new Date(startDate);
                const end = new Date(endDate);
                start.setHours(0, 0, 0, 0); // Set time to midnight
                end.setHours(0, 0, 0, 0); // Set time to midnight
                const diffTime = Math.abs(end - start);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
                return diffDays;
              };

              const countShipmentsByLocation = (shipments) => {
                const cityCounts = {};
                const stateCounts = {};
            
                shipments.forEach(shipment => {
                  if (shipment.status === 0 ||shipment.status === 7 ) {
                    return; // Skip this iteration
                  }
                  // Count sender cities and states
                  const senderCity = shipment.sender[0].city;
                  const senderState = shipment.sender[0].state;
                  if (senderCity) {
                    if (!cityCounts[senderCity]) cityCounts[senderCity] = 0;
                    cityCounts[senderCity]++;
                  }
                  if (senderState) {
                    if (!stateCounts[senderState]) stateCounts[senderState] = 0;
                    stateCounts[senderState]++;
                  }
            
                  // Count receiver cities and states
                //   const receiverCity = shipment.receiver[0].city;
                //   const receiverState = shipment.receiver[0].state;
                //   if (receiverCity) {
                //     if (!cityCounts[receiverCity]) cityCounts[receiverCity] = 0;
                //     cityCounts[receiverCity]++;
                //   }
                //   if (receiverState) {
                //     if (!stateCounts[receiverState]) stateCounts[receiverState] = 0;
                //     stateCounts[receiverState]++;
                //   }
                });
            
                return { cityCounts, stateCounts };
              };
            
              const getTopLocations = (locationCounts, totalShipments, topN = 10) => {
                return Object.entries(locationCounts)
                  .sort(([, a], [, b]) => b - a)
                  .slice(0, topN)
                  .map(([location, count]) => ({
                    location,
                    count,
                    percentage: ((count / totalShipments) * 100).toFixed(2)
                  }));
              };

            //   const options = {
            //     title: {
            //       text: "Shipment Status"
            //     },
            //     data: [{
            //       type: "pie",
            //       startAngle: 75,
            //       toolTipContent: "{name}: {y}%",
            //       showInLegend: "true",
            //       legendText: "{name}",
            //       indexLabelFontSize: 16,
            //     //   indexLabel: "{name} - {y}%",
            //       dataPoints: [
            //         { y: shipstate.total_overview.delivered, name: "Delivered", color: "#28a745" },
            //         { y: shipstate.total_overview.pending, name: "Pending", color: "#dc3545" },
            //         { y: shipstate.total_overview.rto, name: "RTO", color: "#007bff" }
            //       ]
            //     }]
            //   };
          

            React.useEffect(() => {
                const ctx = chartRef.current.getContext('2d');
                const data = {
                  labels: ['Delivered', 'Pending', 'RTO'],
                  datasets: [
                    {
                      data: [shipstate.total_overview.delivered, shipstate.total_overview.pending, shipstate.total_overview.rto],
                      backgroundColor: ['green', 'red', 'blue'],
                      hoverBackgroundColor: ['darkgreen', 'darkred', 'darkblue']
                    }
                  ]
                };
            
                const options = {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom'
                    }
                  }
                };
            
                const carrierBookingChart = new Chart(ctx, {
                  type: 'pie',
                  data: data,
                  options: options
                });
            
                return () => {
                  carrierBookingChart.destroy();
                };
              }, [shipstate]);

             

              const list = ()=>{
                setfinanceloader(true)
                let dataToSend = {customer_id : userData.customer_id  , start_date:financedate.finance_start_date , end_date:financedate.finance_end_date};
            
                // console.log("datatoSend",dataToSend)
                let url = config.apiUrl + '/user/dashboard_shipping_charges_transaction';
                axios.post(url, dataToSend, { headers: config.headers })
                    .then((res) => {
                      // console.log("responseJson => ", res);
                      if(res.data.status==true){
                        setshippingState(res.data.output)
                        setfinanceloader(false)
               }
              else{
                  // const Toast = Swal.mixin({
                  //     toast: true,
                  //     position: 'bottom-end',
                  //     showConfirmButton: false,
                  //     timer: 3000
                  //   })
                  //   Toast.fire({
                  //     background:"rgb(231, 91, 75)",
                  //     type: 'Unsuccess',
                  //     title: "Something Went Wrong !",
                  //     color:"white"
                  //   });
                    setfinanceloader(false)
              } })
                  
                    .catch((error) => {
                      // const Toast = Swal.mixin({
                      //     toast: true,
                      //     position: 'bottom-end',
                      //     showConfirmButton: false,
                      //     timer: 3000
                      //   })
                      //   Toast.fire({
                      //     background:"rgb(231, 91, 75)",
                      //     type: 'Unsuccess',
                      //     title: "Something Went Wrong !",
                      //     color:"white"
                      //   });
                        setfinanceloader(false)
                        // console.log(error);
                    });    
            }
      
            const handleFinanceDate =(e)=>{
              setfinancedate({...financedate , [e.target.name]:e.target.value})
            }

            React.useEffect(() => {
              const ctx = document.getElementById('walletchart').getContext('2d');
            
              // Use actual shipment counts received from the API
              const data = {
                labels: sevendayrecharges.last7DaysRecharges.map(item => item.date),
                datasets: [{
                  data: sevendayrecharges.last7DaysRecharges.map(item => item.amount),
                  borderColor: '#3D348B', // Line color
                  borderWidth: 2,
                  fill: true ,// Fill the area under the line
                  backgroundColor: '#cbc7ea'
                }],
              };
            
              const options = {
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    beginAtZero: true,
                    display: false,
                  },
                },
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 1,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              };
            
              const walletchart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: options,
              });
            
              return () => {
                walletchart.destroy();
              };
            }, [sevendayrecharges]);

            React.useEffect(() => {
              const ctx = document.getElementById('ordersChart').getContext('2d');
            
              // Use actual shipment counts received from the API
              const data = {
                labels: sevendaystate.last7DaysShipments.map(item => item.date),
                datasets: [{
                  data: sevendaystate.last7DaysShipments.map(item => item.count),
                  borderColor: '#3D348B', // Line color
                  borderWidth: 2,
                  fill: true ,// Fill the area under the line
                  backgroundColor: '#cbc7ea'
                }],
              };
            
              const options = {
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    beginAtZero: true,
                    display: false,
                  },
                },
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 1,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              };
            
              const ordersChart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: options,
              });
            
              return () => {
                ordersChart.destroy();
              };
            }, [sevendaystate]);

            useEffect(() => {
              const ctx = document.getElementById('shipmentChart').getContext('2d');
          
              // Data for the bar chart
              const data = {
                labels: statusBookingData.map(entry => entry.status),
                datasets: [{
                  label: 'Shipment Status',
                  data: statusBookingData.map(entry => entry.totalCount),
                  backgroundColor: [
                    '#c19efa', '#3D348B', '#F7B801', '#7678ED', '#F35B04', 
                    '#F18701', '#8338ec', 'rgba(153, 102, 255, 0.2)', 
                    'rgba(153, 102, 255, 0.2)', 'rgba(153, 102, 255, 0.2)'
                  ],
                  borderColor: [
                    '#6610f2', '#302970', '#cb9801', '#1d21e2', '#c94c03', 
                    '#cb7301', '#6e16e9', '#6610f2', '#6610f2', '#6610f2'
                  ],
                  borderWidth: 1
                }]
              };
          
              // Configuration for the chart
              const options = {
                scales: {
                  y: {
                    beginAtZero: true // Start Y axis at 0
                  }
                }
              };
          
              // Create the bar chart
              const shipmentChart = new Chart(ctx, {
                type: 'bar',
                data: data,
                options: options
              });
          
              // Cleanup: Destroy the chart when the component unmounts
              return () => {
                shipmentChart.destroy();
              };
            }, [statusBookingData]);
           
    return (
        <div >
            <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
            <div className="home-section">
         
          <Header/>
               

                {/* <section class="mb-3 pt-3">
                  
                        <div class="header-body">
                            <div class="row">
                                <div class="col-xl-3 col-lg-6">
                                    <div class="card card-stats mb-4 mb-xl-0 bg-primary text-white">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="card-title text-uppercase mb-0">Wallet </h5>
                                                    <span class="h2 font-weight-bold mb-0">&#8377; {balancestate.total_balance.map((sub)=>(
                       parseFloat(sub.wallet_balance).toFixed(2)))}</span>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="icon icon-shape bg-light text-primary rounded-circle shadow">
                                                        <i class="material-icons-outlined">account_balance_wallet</i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pt-3 ">
                                                <a  onClick={(e)=>recharge_page(balancestate.total_balance.map((sub)=>(
                    sub)))} class="btn btn-outline-light btn-sm">Recharge</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6">
                                    <div class="card card-stats mb-4 mb-xl-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="card-title text-uppercase text-muted mb-0">Today's Order</h5>
                                                    <span class="h2 font-weight-bold mb-0">{state.today_orders}</span>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                        <i class="material-icons-outlined">local_mall</i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="mt-2 mb-0 text-muted text-sm">
                                                <span class="text-primary mr-2">{state.yesterday_orders}</span><br />
                                                <span class="text-nowrap">Yesterday's Order</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6">
                                    <div class="card card-stats mb-4 mb-xl-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="card-title text-uppercase text-muted mb-0">Today's Revenue</h5>
                                                    <span class="h2 font-weight-bold mb-0"><small></small>&#8377;{state.today_revenue}</span>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="icon icon-shape bg-success text-white rounded-circle shadow">
                                                        <i class="material-icons-outlined">savings</i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="mt-2 mb-0 text-muted text-sm">
                                                <span class="text-primary mr-2">&#8377;{state.yesterday_revenue}</span><br />
                                                <span class="text-nowrap">Yesterday's Revenue</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6">
                                    <div class="card card-stats mb-4 mb-xl-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="card-title text-uppercase text-muted mb-0">Today's Shipments</h5>
                                                    <span class="h2 font-weight-bold mb-0">{state.today_shipments}</span>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="icon icon-shape bg-primary text-white rounded-circle shadow">
                                                        <i class="material-icons-outlined">view_in_ar</i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="mt-2 mb-0 text-muted text-sm">
                                                <span class="text-primary mr-2">{state.yesterday_shipments}</span><br />
                                                <span class="text-nowrap">Yesterday's Shipments</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  
                </section> */}
                {/* <section class="mb-3">
                  
                  <ul class="nav nav-pills d-flex justify-content-center align-items-center p-2">
                      <li class="nav-item">
                          <a class="nav-link active text-decoration-none" href="#">Overview</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link text-decoration-none" href="/customer/orders">Orders</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link text-decoration-none" href="/customer/shipments">Shipments</a>
                      </li>
                     
                  </ul>
                 
         
          </section> */}
                

                {/* <div className="row mb-2">
                      <div className="col-12">
                        <div className='row justify-content-end'>
                            <div className='col-6 col-md-3'>
                            <div className="row mb-2">
                  
                      <div className="col-6">  
                      <input
          className="form-control"
          type="date"
          name="start_date"
          value={selectedRange[0].toISOString().split('T')[0]}
          onChange={handleStartDateChange}
        /></div>
                      <div className="col-6">
                      <input
          className="form-control"
          type="date"
          value={selectedRange[1].toISOString().split('T')[0]}
          onChange={handleEndDateChange}
        />
                      </div>

                    </div>
                            </div>
                            <div className='col-6 col-md-2'>
                            <ul class="nav nav-pills ">
                      <li class="nav-item">
                          <a class="nav-link active text-decoration-none" style={{cursor:"pointer"}} onClick={(e)=>get_full_dashboard_data(e)}>Search</a>
                      </li>
                      </ul>
                            </div>

                            
                        </div>
                     
                   
                      </div>

                    </div> */}

                    {/* <div className='card'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12 col-md-2'>  {shipstate.today_shipments} <br/>Today's Shipments</div>
                                <div className='col-12 col-md-2'>  {shipstate.today_failed} <br/>Today's Failed</div>
                                <div className='col-12 col-md-2'>  {shipstate.pickup_pending} <br/>Pickup Pending</div>
                                <div className='col-12 col-md-2'>  {shipstate.month_shipments} <br/>Month Shipments</div>
                                <div className='col-12 col-md-2'>  {shipstate.total_shipments} <br/>Total Shipments</div>
                                <div className='col-12 col-md-2'>  {shipstate.today_channel_orders} <br/>Today's Channel Orders</div>
                             

                            </div>
                        </div>
                    </div>

                   */}
                   

             

<section class="mb-3 dashboard_box_ne">
                <div class="dash_navs">
                    <ul class="nav nav-tabs justify-content-center">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#home">Overview</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#menu1">Analytics</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#menu2">Finance</a>
                        </li>
                    </ul>
                </div>

                <div class="tab-content mt-3 pb-5">
                    <div class="tab-pane container active" id="home">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h4>Today Stats</h4>
                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bx bx-cart"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>Shipments</h5>
                                           
    
                                            <h2>{shipstate.today_shipments_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_rea_pickup shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bx bx-upload"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>Pending Pickup</h5>
                                            <h2>{shipstate.today_pickup_pending_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_pickdup shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bx bx-package"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>Picked Up</h5>
                                            <h2>{shipstate.today_picked_up}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_intransit shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bx bx-bus"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>In Transit</h5>
                                            <h2>{shipstate.today_in_transit_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_delivered shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bx bx-message-square-check"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>Delivered</h5>
                                            <h2>{shipstate.today_delivered_shipments_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_ndr shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bx bx-message-square-error"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>NDR</h5>
                                            <h2>{shipstate.today_ndr_shipments_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>

                        <section class="mb-3 ">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <div class="card border-0 shadow-sm">
                                      <div class="card-body">
                                        <h4>COD Stats</h4>
                                        <div class="row">
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_1">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bx bx-money-withdraw"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Next COD</h5>
                                                    <h2>----</h2>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_2">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bx bx-money"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Upcoming COD</h5>
                                                    <h2>----</h2>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_3">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bx bx-wallet"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Last COD</h5>
                                                    <h2>----</h2>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <div class="card border-0 shadow-sm">
                                      <div class="card-body">
                                        <h4>NDR Stats</h4>
                                        <div class="row">
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_1">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bx bx-check-double"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Open NDR(s)</h5>
                                                    <h2>----</h2>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_2">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bx bx-check-circle"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Active NDR(s)</h5>
                                                    <h2>----</h2>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_3">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bx bx-x-circle"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Closed NDR(s)</h5>
                                                    <h2>----</h2>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="mb-3 mt-3">
                            <div class="">
                              <div class="row">
                                  <div class="col-12 col-md-6 ">
                                          <div class="wallet_card shadow-sm">
                                              <div class="wallet_card_box">
                                                  <div class="row">
                                                      <div class="col-12 col-md">
                                                          <h4>Today's Wallet Recharge</h4>
                                                          <h1>{sevendayrecharges.totalRechargesToday}</h1>
                                                          <h6>{sevendayrecharges.totalRechargesLast7Days} spent in last 7 days</h6>
                                                      </div>
                                                      <div class="col-12 col-md-4 text-end">
                                                          <a href="">Wallet Recharge</a>
                                                      </div>
                                                  </div>
                                              </div>
                                              <canvas id="walletchart" width="632" height="158" style={{display: "block", boxSizing: "border-box", height: "158px", width: "632px"}}></canvas>
                                          </div>
                                  </div>
                                  <div class="col-12 col-md-6 ">
                                          <div class="shipment_card shadow-sm">
                                              <div class="shipment_card_box">
                                                  <div class="row">
                                                      <div class="col-12 col-md">
                                                          <h4>Today's Shipments</h4>
                                                          <h1>{shipstate.today_shipments_stats}</h1>
                                                          <h6>{sevendaystate.totalShipmentsCount} shipments in last 7 days</h6>
                                                      </div>
                                                      <div class="col-12 col-md-4 text-end">
                                                          <a href="#">Shipment List</a>
                                                      </div>
                                                  </div>
                                              </div>
                                              <canvas id="ordersChart" width="632" height="158" style={{display: "block", boxSizing: "border-box", height: "158px", width: "632px"}}></canvas>
                                          </div>
                                  </div>
                              </div>      
                            </div>
                        </section>
                           
                        <div class="card border-0 mb-3 shadow-sm">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div class="">
                                        <h4>Shipments</h4>
                                    </div>
                                    <div class="">
                                        <small>Last 7 days</small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md shipment_state">
                                        <h1>Shipment Status</h1>
                                        <div class="row">
                                            <div class="col-12 col-md">
                                         
                                                <h2>{shipstate.today_shipments_stats}</h2>
                                                <p>Today</p>
                                            </div>
                                            <div class="col-12 col-md">
                                                <h2>{sevendaystate.totalShipmentsCount}</h2>
                                                <p>Last 7 days</p>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-8">
                                        <canvas id="shipmentChart" width="832" height="208" style={{display: "block", boxSizing: "border-box", height: "208px", width: "832px"}}></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>

                       

                    </div>
                    <div class="tab-pane container fade" id="menu1">

                        <div class="row justify-content-end">
                        <div class="col-12 col-md-3 text-end ">
                                <div class="input-group mb-3 input-group-sm">
                                    <span class="input-group-text"><i class="bx bx-calendar"></i></span>
                                    <input type="date"   name="start_date"
          value={selectedRange[0].toISOString().split('T')[0]}
          onChange={handleStartDateChange} class="form-control"/>
                                </div>
                                
                            </div>
                            <div class="col-12 col-md-3 text-end ">
                                <div class="input-group mb-3 input-group-sm">
                                    <span class="input-group-text"><i class="bx bx-calendar"></i></span>
                                    <input type="date" class="form-control"  value={selectedRange[1].toISOString().split('T')[0]}
          onChange={handleEndDateChange}/>
                                    <button class="btn btn-primary" onClick={(e)=>get_full_dashboard_data(e)}><i class="bx bx-search"></i></button>
                                </div>
                                
                            </div>
                        </div>
                        <div class="card border-0 shadow-sm mb-3">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bx bx-cart"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>Total Shipments</h5>
                                              <h2>{shipstate.today_shipments}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_rea_pickup shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bx bx-upload"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>Pending Pickup</h5>
                                              <h2>{shipstate.pickup_pending}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_intransit shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bx bx-bus"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>In Transit</h5>
                                              <h2>{shipstate.today_in_transit}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_delivered shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bx bx-message-square-check"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>Delivered</h5>
                                              <h2>{shipstate.delivered_shipments}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_ndr shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bx bx-message-square-error"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>Total NDR</h5>
                                              <h2>{shipstate.ndr_shipments}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_rto shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bx bx-package"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>Total RTO</h5>
                                              <h2>{shipstate.rto_shipments}</h2>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
  
                          <div class="card border-0 shadow-sm mb-3 order_summary">
                            <div class="card-body">
                              <h4>Shipment Summary</h4>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                      <th scope="col">SNO.</th>
                                                      <th scope="col">CARRIERS</th>
                                                      <th scope="col">COD</th>
                                                      <th scope="col">PREPAID</th>
                                                      <th scope="col">TOTAL</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                              {shipstate.order_summary.map((order, index) => (
                                                  <tr>
                                                      <td data-label="SNO.">{index+1}</td>
                                                      <td data-label="CARRIERS">{order.carrier}</td>
                                                      <td data-label="COD">{order.cod}</td>
                                                      <td data-label="PREPAID">{order.prepaid}</td>
                                                      <td data-label="TOTAL">{order.cod}</td>
                                                  </tr>
                                              ))}
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
  
                          <div class="card border-0 shadow-sm mb-3 carrier_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>Carrier Overview</h4>
                                  </div>
                                  {/* <div class="col-12 col-md-2 text-end">
                                      <select class="form-select">
                                        <option>All</option>
                                        <option>Prepaid</option>
                                        <option>COD</option>
                                      </select>
                                  </div> */}
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                      <th scope="col">SNO.</th>
                                                      <th scope="col">Carrier NAME</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">RUNNING</th>
                                                      <th scope="col">RETURN TO ORIGIN</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                              {filteredCourierOverview.map((overview, index) => (
                  <tr key={index}>
                      <td data-label="SNO.">{index+1}</td>
                    <td data-label="Carrier NAME">{overview.carrier}</td>
                    <td data-label="SHIPMENTS BOOKED">{overview.booked} </td>
                    <td data-label="DELIVERED">{overview.delivered} ({overview.deliveredPercentage}%)</td>
                    <td data-label="RUNNING">{overview.pending} ({overview.pendingPercentage}%)</td>
                    <td data-label="RETURN TO ORIGIN">{overview.rto} ({overview.rtoPercentage}%)</td>
                  </tr>
                ))}
                                                
                                                  <tr class="total table-info">
                                                      <td data-label="" colspan="2">Total</td>
                                                      <td data-label="TOTAL SHIPMENTS BOOKED">{shipstate.total_overview.booked}</td>
                                                      <td data-label="TOTAL DELIVERED">{shipstate.total_overview.delivered}0<span class="text-success"> ({(shipstate.total_overview.delivered ? (shipstate.total_overview.delivered / shipstate.total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      <td data-label="TOTAL RUNNING">{shipstate.total_overview.pending}<span class="text-warning">({(shipstate.total_overview.pending ? (shipstate.total_overview.pending / shipstate.total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      <td data-label="TOTAL RETURN TO ORIGIN">{shipstate.total_overview.rto}<span class="text-danger">({(shipstate.total_overview.rto ? (shipstate.total_overview.rto / shipstate.total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
  
                          <div class="card border-0 shadow-sm mb-3 perfrom_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>Performance Overview</h4>
                                  </div>
                                  <div class="col-12 col-md-6 text-end">
                                      <div class="row">
                                          {/* <div class="col-12 col-md-4">
                                              <select class="form-select">
                                                  <option>Carriers</option>
                                                  <option>Ecom (Surface - 0.5KG)</option>
                                                  <option>Delhivery (Surface - 0.5KG)</option>
                                              </select>
                                          </div> */}
                                          {/* <div class="col-12 col-md-4">
                                              <select class="form-select">
                                                  <option>Warehouse</option>
                                                  <option>Mumbai Warehouse</option>
                                                  <option>Jaipur Warehouse</option>
                                              </select>
                                          </div> */}
                                          {/* <div class="col-12 col-md-4">
                                              <select class="form-select">
                                                  <option>All</option>
                                                  <option>Prepaid</option>
                                                  <option>COD</option>
                                              </select>
                                          </div> */}
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                      <th scope="col">DATE</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">RUNNING</th>
                                                      <th scope="col">RETURN TO ORIGIN</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                              {shipstate.performanceOverview.map((overview, index) => (
                <tr key={index}>
                  <td data-label="DATE">{overview.date}</td>
                  <td data-label="SHIPMENTS BOOKED">{overview.booked}</td>
                  <td data-label="DELIVERED">{overview.delivered} <span class="text-success">({overview.deliveredPercentage}%)</span></td>
                  <td data-label="RUNNING">{overview.pending} <span class="text-warning">({overview.pendingPercentage}%)</span></td>
                  <td data-label="RETURN TO ORIGIN">{overview.rto}<span class="text-danger">({overview.rtoPercentage}%)</span></td>
                </tr>
              ))}

<tr class="total table-info">
<td data-label="TOTAL">Total</td>
<td data-label="SHIPMENTS BOOKED">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0)}</td>
                <td data-label="DELIVERED">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.delivered, 0)}<span class="text-success">({(shipstate.performanceOverview.reduce((acc, curr) => acc + curr.delivered, 0) ? (shipstate.performanceOverview.reduce((acc, curr) => acc + curr.delivered, 0) / shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0) * 100).toFixed(2) : '0.00')}%)</span> </td>

                <td data-label="RUNNING">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.pending, 0)}<span class="text-warning">({(shipstate.performanceOverview.reduce((acc, curr) => acc + curr.pending, 0) ? (shipstate.performanceOverview.reduce((acc, curr) => acc + curr.pending, 0) / shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0) * 100).toFixed(2) : '0.00')}%)</span>
                 </td>

                <td data-label="RETURN TO ORIGIN">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.rto, 0)}<span class="text-danger">({(shipstate.performanceOverview.reduce((acc, curr) => acc + curr.rto, 0) ? (shipstate.performanceOverview.reduce((acc, curr) => acc + curr.rto, 0) / shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0) * 100).toFixed(2) : '0.00')}%)</span>
                 </td>
              </tr>                      
                                                
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>

                          {/* <div class="card border-0 shadow-sm mb-3 state_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>State Wise Overview</h4>
                                  </div>
                                  <div class="col-12 col-md-2 text-end">
                                        <select class="form-select">
                                            <option>All</option>
                                            <option>Prepaid</option>
                                            <option>COD</option>
                                        </select>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                      <th scope="col">STATE NAME</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">RUNNING</th>
                                                      <th scope="col">RETURN TO ORIGIN</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td data-label="STATE NAME">Rajasthan</td>
                                                      <td data-label="SHIPMENTS BOOKED">100</td>
                                                      <td data-label="DELIVERED">10 <span class="text-success">(10%)</span> </td>
                                                      <td data-label="RUNNING">50 <span class="text-warning">(50%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">40<span class="text-danger">(40%)</span></td>
                                                  </tr>
                                                  <tr>
                                                      <td data-label="STATE NAME">Punjab</td>
                                                      <td data-label="SHIPMENTS BOOKED">10</td>
                                                      <td data-label="DELIVERED">10<span class="text-success">(100%)</span></td>
                                                      <td data-label="RUNNING">0<span class="text-warning">(0%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">0<span class="text-danger">(0%)</span></td>
                                                  </tr>
                                                  <tr class="total table-info">
                                                      <td data-label="TOTAL">Total</td>
                                                      <td data-label="SHIPMENTS BOOKED">110</td>
                                                      <td data-label="DELIVERED">20<span class="text-success">(12%)</span></td>
                                                      <td data-label="RUNNING">50<span class="text-warning">(49%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">40<span class="text-danger">(39%)</span></td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>

                          <div class="card border-0 shadow-sm mb-3 city_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>City Wise Overview</h4>
                                  </div>
                                  <div class="col-12 col-md-2 text-end">
                                        <select class="form-select">
                                            <option>All</option>
                                            <option>Prepaid</option>
                                            <option>COD</option>
                                        </select>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                      <th scope="col">CITY NAME</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">RUNNING</th>
                                                      <th scope="col">RETURN TO ORIGIN</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td data-label="CITY NAME">Jaipur</td>
                                                      <td data-label="SHIPMENTS BOOKED">100</td>
                                                      <td data-label="DELIVERED">10 <span class="text-success">(10%)</span> </td>
                                                      <td data-label="RUNNING">50 <span class="text-warning">(50%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">40<span class="text-danger">(40%)</span></td>
                                                  </tr>
                                                  <tr>
                                                      <td data-label="CITY NAME">Delhi</td>
                                                      <td data-label="SHIPMENTS BOOKED">10</td>
                                                      <td data-label="DELIVERED">10<span class="text-success">(100%)</span></td>
                                                      <td data-label="RUNNING">0<span class="text-warning">(0%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">0<span class="text-danger">(0%)</span></td>
                                                  </tr>
                                                  <tr class="total table-info">
                                                      <td data-label="TOTAL">Total</td>
                                                      <td data-label="SHIPMENTS BOOKED">110</td>
                                                      <td data-label="DELIVERED">20<span class="text-success">(12%)</span></td>
                                                      <td data-label="RUNNING">50<span class="text-warning">(49%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">40<span class="text-danger">(39%)</span></td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div> */}

                          <div class="card border-0 shadow-sm mb-3 zone_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>Zone Wise Overview</h4>
                                  </div>
                                  {/* <div class="col-12 col-md-2 text-end">
                                        <select class="form-select">
                                            <option>All</option>
                                            <option>Prepaid</option>
                                            <option>COD</option>
                                        </select>
                                  </div> */}
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                      <th scope="col">ZONE NAME</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">RUNNING</th>
                                                      <th scope="col">RETURN TO ORIGIN</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                              {shipstate.zone_overview.map((overview, index) => (
                  <tr key={index}>
                    
                    <td data-label="ZONE NAME">{overview.zone_name}</td>
                    <td data-label="SHIPMENTS BOOKED">{overview.booked} </td>
                    <td data-label="DELIVERED">{overview.delivered} <span class="text-success">({overview.deliveredPercentage}%)</span> </td>
                    <td data-label="RUNNING">{overview.pending} <span class="text-warning">({overview.pendingPercentage}%)</span></td>
                    <td data-label="RETURN TO ORIGIN">{overview.rto} <span class="text-danger">({overview.rtoPercentage}%)</span></td>
                  </tr>
                ))}
                
                                                 <tr class="total table-info">
                                                      <td data-label="TOTAL">Total</td>
                                                      <td data-label="SHIPMENTS BOOKED">{shipstate.zone_total_overview.booked}</td>
                                                      <td data-label="DELIVERED">{shipstate.zone_total_overview.delivered} <span class="text-success"> ({(shipstate.zone_total_overview.delivered ? (shipstate.zone_total_overview.delivered / shipstate.zone_total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      <td data-label="RUNNING">{shipstate.zone_total_overview.pending}<span class="text-warning">({(shipstate.zone_total_overview.pending ? (shipstate.zone_total_overview.pending / shipstate.zone_total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">{shipstate.zone_total_overview.rto}<span class="text-danger">({(shipstate.zone_total_overview.rto ? (shipstate.zone_total_overview.rto / shipstate.zone_total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
  
  
                          <div class="row">
                              <div class="col-12 col-md-6">
                                  <div class="card border-0 shadow-sm mb-3">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                <h4>Shipments Status</h4>
                                            </div>
                                            <div class="col-12 col-md-6 text-end">
                                                <div class="row">
                                                    {/* <div class="col-6 col-md">
                                                        <select class="form-select">
                                                            <option>Carriers</option>
                                                            <option>Ecom (Surface - 0.5KG)</option>
                                                            <option>Delhivery (Surface - 0.5KG)</option>
                                                        </select>
                                                    </div> */}
                                                    {/* <div class="col-6 col-md">
                                                        <select class="form-select">
                                                            <option>All</option>
                                                            <option>Prepaid</option>
                                                            <option>COD</option>
                                                        </select>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-12">
                                            <canvas ref={chartRef}></canvas>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 col-md-6">
                                  <div class="card border-0 shadow-sm mb-3">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                <h4>Top 10</h4>
                                            </div>
                                            <div class="col-12 col-md-6 text-end">
                                                <div class="row">
                                                    {/* <div class="col-6">
                                                        <select class="form-select">
                                                            <option>Carriers</option>
                                                            <option>Ecom (Surface - 0.5KG)</option>
                                                            <option>Delhivery (Surface - 0.5KG)</option>
                                                        </select>
                                                    </div> */}
                                                    {/* <div class="col-6">
                                                        <select class="form-select">
                                                            <option>All</option>
                                                            <option>Prepaid</option>
                                                            <option>COD</option>
                                                        </select>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-12 col-md-6">
                                            <h6>Top City</h6>
                                            <table class="table">
                                                <tbody>
                                                {topCities.map((city, index) => (
              <tr key={index}>
                <td>{city.location}</td>
                <td>{city.count} <span class="text-primary">({city.percentage}%)</span> </td>
             
              </tr>
            ))}
                                                
                                                </tbody>
                                              </table>
                                          </div>
                                          <div class="col-12 col-md-6">
                                              <h6>Top State</h6>
                                              <table class="table">
                                                  <tbody>
                                                  {topStates.map((state, index) => (
              <tr key={index}>
                <td>{state.location} </td>
                <td>{state.count} <span class="text-primary">({state.percentage}%)</span></td>
             
              </tr>
            ))}
                                                   
                                                  </tbody>
                                                </table>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                    </div>
                    <div class="tab-pane container fade" id="menu2">
                        
                        <div class="card border-0 shadow-sm mb-3">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md">
                                        <h4>Billing Summary</h4>
                                    </div>
                                    <div class="col-12 col-md-3 text-end ">
                                <div class="input-group mb-3 input-group-sm">
                                    <span class="input-group-text"><i class="bx bx-calendar"></i></span>
                                    <input type="date" name="finance_start_date" value={financedate.finance_start_date} onChange={handleFinanceDate} class="form-control"/>
                                </div>
                                
                            </div>
                            <div class="col-12 col-md-3 text-end ">
                                <div class="input-group mb-3 input-group-sm">
                                    <span class="input-group-text"><i class="bx bx-calendar"></i></span>
                                    <input type="date" class="form-control"  value={financedate.finance_end_date} onChange={handleFinanceDate} name="finance_end_date"/>
                                    <button class="btn btn-primary" onClick={()=>list()}><i class="bx bx-search"></i></button>
                                </div>
                                
                            </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="table-responsive">
                                        {financeloader? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"50px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
    </div>:
                                            <table class="table">
                                                <thead class="table-primary">
                                                    <tr>
                                                        <th scope="col">Transaction ID</th>
                                                        <th scope="col">AWB Number</th>
                                                        <th scope="col">Weight & Zone</th>
                                                        <th scope="col">Carrier</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Credit</th>
                                                        <th scope="col">Debit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* <tr>
                                                            <td data-label="Transaction ID">1718979598659
                                                                <div><small>Jun 21 2024 - 07:49:48 pm</small></div>
                                                            </td>
                                                            <td data-label="AWB Number"><a href="">SF1182472339SIB</a></td>
                                                            <td data-label="Weight & Zone">1 KG
                                                                <div><small>ROI Zone</small></div>
                                                            </td>
                                                            <td data-label="Carrier">Shadowfax
                                                                <div><small>Small 1KG</small></div>
                                                            </td>
                                                            <td data-label="Status">
                                                                Shipping Charges
                                                            </td>
                                                            <td data-label="Credit">
                                                                <div class="text-success"></div>
                                                            </td>
                                                            <td data-label="Debit">
                                                                <div class="text-danger">₹60</div>
                                                            </td>
                                                    </tr> */}
                                                    {shippingstate.length>0?
                        shippingstate.map((sub)=>(
                     <tr>
                        <td>{sub.transaction_id}<br/><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(sub.created*1000)}
            </Moment></td>
                        <td>{sub.carrier_tracking_number}</td>
                        <td>{sub.total_weight} KG<br/>
                        {sub.zone_name?sub.zone_name:""}</td>

                        <td>{sub.carrier_name}<br/>{sub.service_provider}</td>
                        <td>{sub.status==6 || sub.status==7? "Refunded":"Shipping Charges"}</td>
                        <td className='text-success'>{sub.status==6 || sub.status==7?<>₹ {sub.total_amount}</> :""}</td>
                        <td className='text-danger'> {sub.status!=6 && sub.status!=7 && sub.status!=0?<>- ₹ {sub.total_amount}</> :""}</td>
                        </tr>
                     )) : 
                     <tr>
                     <td colspan="12" class="text-center">
                         Sorry! There is no data available at the moment.
                     </td>
                   </tr>}
                                                </tbody>
                                            </table>
}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </div>

     
          
            <Footer value={1}/>
        </div>
    )
}

export default New_dashboard
