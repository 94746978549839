import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';
import { CornerUpLeft } from 'lucide-react'
import Footer from '../Footer'

const Channel_list = () => {
    let navigate = useNavigate();
    const[state,setState] = React.useState([])
    const [ statusState,setStatus] = React.useState(null)
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    React.useEffect(()=>{
        getChannelList()
    },[])
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
    const getChannelList = () => {


        let url = config.apiUrl + '/user/channel_list';
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            // console.log("res ", res);  
            if(res.data.status==true){         
            setState(res.data.output)
            }
            else{
                Toast.fire({
                    background:"#c2200a",
                    type: 'unsuccess',
                    title: "Something Went Wrong",
                    color:"white"
                  });
            }
    
        }).catch((e) => {
            Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
    
        });
    }
    const updateStatus = (status  , store_id) => {


        let url = config.apiUrl + '/user/update_store_status';
        let sendData = {status:status  , store_id:store_id};
        // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            if(res.data.status==true){
            Toast.fire({
                background:"#001b72",
                type: 'unsuccess',
                title: "Updated Successfully",
                color:"white"
              });
            }
    
        }).catch((e) => {
    
            Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
        });
    }

    const handleChange = (e,  store_id) => {
        // console.log("e ", e.target.name);
        // console.log("e checked", e.target.checked);
        if (e.target.checked == true) {
          setStatus({ ...statusState, status: 1 })
          updateStatus(1  , store_id)
        }
        else {
            setStatus({ ...statusState, status: 0 })
            updateStatus(0  , store_id)
        
        }
      }
  return (
//     <div>
     
// <Left_menu value={11}/>
//     <section class="home ">
       
// <Header/>
        

//         <section class="mb-3 mt-3 tools">
//         <div class="container">
//             <div class="row py-3">
//                 <div class="col-12 col-md">
//                     <h3>Channel List</h3>
//                 </div>
//                 <div class="col-12 col-md-4 text-end">
//                     <a href="/customer/add_channel" class="btn btn-primary">Add Chanel</a>
//                 </div>
//             </div>
//             <div class="row chanel_list">
// {state.length>0?
//                 state.map((item)=>(
                  
//                 <div class="col-12 col-md-4 mb-3">
//                         <div class="card  shadow-sm ">
//                             <div class="card-body">
//                                 <div class="text-center">
//                                     <figure>
//                                         <img src={`https://cdn.kourierwale.co.in/channel_images/${item.channel_img}`} class="img-fluid" style={{height:"100px"}} />
//                                     </figure>
//                                     <h4>{item.channel_name}</h4>
//                                 </div>
                               
//                                 <div>
//                                 <small>Store Name</small>
//                             <h6 class="pt-3">{item.store_name}</h6>
//                             <span className='badge rounded-pill  bg-warning'>{item.status==5?"Pending":""}</span>

//                             <hr/>
//                             <div class="d-flex justify-content-between">
//                                 <div class="">
//                                     {item.status!=5?
//                                     <div class="form-check form-switch  mb-2">
//                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked={item.status} onChange={(e)=>handleChange(e , item.store_id)} name="status"/>
//                                        <label class="form-check-label" for="flexSwitchCheckChecked"><small>Active/Inactive</small></label>
//                                     </div>
//                                     :""}
//                                 </div>
//                                 <div class=" ">
//                                     <ul class="nav justify-content-end">
//                                         {/* <li class="nav-item">
//                                             <a class="nav-link text-danger" href="#" data-bs-toggle="tooltip" title="Unlink Store"><i class="material-icons-outlined">delete</i></a>
//                                         </li> */}
//                                         {item.channel_id=="1507808901"?
//                                         <li class="nav-item">
//                                             <a class="nav-link text-danger" href={`/customer/channel_integrate/${item.channel_id}?user_id=${item.unique_user}`} data-bs-toggle="tooltip" title="Unlink Store"><i class="material-icons-outlined">edit</i></a>
//                                         </li>
//                                         :""}
//                                     </ul>
//                                 </div>
                               
//                             </div>
//                             <div>
//                                     {item.status==2?"Error Please check your credientials":""}
//                                 </div>
//                             </div>


//                         </div>
//                     </div>
//                 </div>
                
// )):""}
//             </div>
//         </div>  
//         </section>
        
//     </section>


    
//     </div>
<div >
<Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>

    <div class="row mb-2">
        <div class="col-12 col-md">
            <div class="d-flex">
                <div class="back_btn_wrap">
                    <a onClick={(e) => {
        e.preventDefault();
        navigate(-1); // This takes the user to the previous page
      }} class="back_btn">
                   <CornerUpLeft/>
                    </a>
                </div>
                <div class="">
                    <h2>Channels</h2>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-5 text-end btns">
            <a href="/customer/add_channel" class="btn btn-primary">Connect Channel</a>
        </div>
    </div>
    
    {state.length>0?
                 state.map((item)=>(
    <section>
        <div class="row channel_listing">
            <div class="col-12 col-md-6 mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-2">
                            <figure>
                                <img src={`https://kourier-admin-panel.s3.ap-south-1.amazonaws.com/channel_images/${item.channel_img}`} class="img-fluid" />
                            </figure>
                        </div>
                        <div class="col-12 col-md">
                            <h4>{item.store_name} </h4>
                            <p>Store ID: #{item.store_id}</p>
                            <p>Store url: {item.store_url}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <span class="badge rounded-pill bg-danger"><small>{item.status==5?"Pending":""}</small></span>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        {/* <div class="col-12 col-md-8">
                            <a href="#" class="btn btn-sm btn-secondary-o"><i data-lucide="arrow-left-right"></i> Connections</a>
                        </div> */}
                        <div class="col-12 col-md text-end">
                            <div class="d-flex justify-content-end">
                                <div class="">
                                    {/* <a href=""><i data-lucide="settings"></i></a> */}
                                    {item.channel_id=="1507808901" ?
                                        
                                        <a class="nav-link text-danger" href={`/customer/channel_integrate/${item.channel_id}?user_id=${item.unique_user}`} data-bs-toggle="tooltip" title="Unlink Store"><i class="material-icons-outlined">edit</i></a>
                                 
                                    : <a class="nav-link text-danger" href={`/customer/edit_channel_integrate/${item.channel_id}/${item.store_id}`} data-bs-toggle="tooltip" title="Unlink Store"><i class="material-icons-outlined">edit</i></a>}
                                </div>
                                
                                <div class="ps-2 ">
                                {item.status!=5?
                                    <div class="form-check form-switch  mb-2">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked={item.status} onChange={(e)=>handleChange(e , item.store_id)} name="status"/>
                                        <label class="form-check-label" for="flexSwitchCheckChecked"><small>Active/Inactive</small></label>
                                     </div>
                                     :""}
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
           
        </div>
    </section>
                 )):""}
    
</div>

    

{/* <!-- Main content area END --> */}
<Footer/>
</div>
  )
}

export default Channel_list
