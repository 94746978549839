import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';

import Footer from '../Footer'


const Edit_channel_integrate = () => {
    let navigate = useNavigate();
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
 const {channel_id , store_id } = useParams();
    const[state,setState] = useState({user_info:{}})
   
    const[isLoading , setisLoading] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    React.useEffect(()=>{
        getShopifyDetails()
    },[])

    const handleChange = (e)=>{
       
        console.log("e", e);
      let userTemp = {...state.user_info};
      userTemp[e.target.name] =  e.target.value;
       setState({ ...state, user_info:userTemp });
    }
   
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })

    const addStore = ()=>{
     setisLoading(true)
     if(state.user_info.store_name!="" && state.user_info.store_url!="" && state.user_info.store_api_key !=""){
        let url = config.apiUrl + '/shipment/store_update_test_api';
        let sendData = {channel_id:channel_id ,store_id:store_id, store_name:state.user_info.store_name , store_url:state.user_info.store_url , store_api_key : state.user_info.store_api_key  };
        // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            if(res.data.status==true){    
                
                Toast.fire({
                    background:"#001b72",
                    type: 'unsuccess',
                    title: "Store Updated Successfully",
                    color:"white"
                  });
                  setisLoading(false)
    navigate('/customer/channel_list')
              
            }
            else{
                Toast.fire({
                    background:"#c2200a",
                    type: 'unsuccess',
                    title: res.data.message,
                    color:"white"
                  });
                  setisLoading(false)
            }
    
        }).catch((e) => {
            Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
              setisLoading(false)
    
        });
    }
    else{
        Toast.fire({
            background:"#c2200a",
            type: 'unsuccess',
            title: "Fill All the fields",
            color:"white"
          });
          setisLoading(false)
    }
    }

    const getShopifyDetails = ()=>{
       
           let url = config.apiUrl + '/shipment/get_shopify_store_details';
           let sendData = {channel_id:channel_id ,store_id:store_id };
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               if(res.data.status==true){    
                   setState({...state,user_info:res.data.output[0]})
                  }
               else{
                   Toast.fire({
                       background:"#c2200a",
                       type: 'unsuccess',
                       title: res.data.message,
                       color:"white"
                     });
                     
               }
       
           }).catch((e) => {
               Toast.fire({
                   background:"#c2200a",
                   type: 'unsuccess',
                   title: "Something Went Wrong",
                   color:"white"
                 });
       
           });
       }

      

      
  return (
    <div>
       
       <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
  <section class="mb-3 mt-3 shopify">
          <div class="container">
           <h3 class="py-3">Integrate Shopify</h3> 
            
                

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-5">
                        <figure>
                            <img src="../../../img/shopify.png" class="img-fluid" style={{height:"100px"}} />
                        </figure>

                        <h5>Shopify</h5>
                    <p>Use the following steps to Integrate Shopify </p>
                    <ol>
                        <li>Login into Shopify Admin panel</li>
                        <li>Go to Settings</li>
                        <li>Scroll down left menu to find Apps and Sales Channel.</li>
                        <li>Here, click on Develop Apps and then click on Create an app</li>
                        <li>Go ahead and give your app a appropiate name. For eg. Kourierwale</li>
                        <li>After creating app you will see four tabs click on Configuration then in Admin API integration click on configure</li>
                        <li>Next, we need some admin access for some speicific apis 
                            <ul>
                                {/* <li><strong>Read Access:</strong> Fullfillment Service & Inventory</li> */}
                                <li><strong>Read & Write Access:</strong> write_assigned_fulfillment_orders, read_assigned_fulfillment_orders, write_customers, read_customers, write_draft_orders, read_draft_orders, write_fulfillments, read_fulfillments, write_merchant_managed_fulfillment_orders, read_merchant_managed_fulfillment_orders, write_orders, read_orders, write_shipping, read_shipping, write_third_party_fulfillment_orders, read_third_party_fulfillment_orders, write_custom_fulfillment_services, read_custom_fulfillment_services, write_fulfillment_constraint_rules, read_fulfillment_constraint_rules</li>
                            </ul>
                        </li>
                        <li>Save your changes and go to Overview and install your app after installing you will get Admin API access token
                            <ul>
                                <li><strong>Admin API access token </strong></li>
                                <li><strong>Note:</strong> For store url use: <br/>
                                yourstorename.myshopify.com</li>
                            </ul>
                        </li>
                        <li>Paste the same credentials here to integrate the shopfiy channel</li>
                    </ol>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Setting</h6>
                            <div class="row">
                           
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Store Name:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Name" name="store_name" onChange={(e)=>handleChange(e)} value={state.user_info.store_name} />
                                </div>
                               
                                 
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Store URL:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter URL Link" name="store_url" onChange={(e)=>handleChange(e)}  value={state.user_info.store_url}/>
                                </div>
                                
                                <div class="col-12 col-md-12 mb-3">
                             <label for="email" class="form-label">ACCESS TOKEN KEY:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter ACCESS TOKEN KEY" name="store_api_key" onChange={(e)=>handleChange(e)}  value={state.user_info.store_api_key}/>
                                </div>
                            </div>

                            <div class="py-4">
                            {isLoading? 
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /> :
                            <a onClick={()=>addStore()} class="btn btn-primary">Update Store</a>
                            }
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
          </div>
        </section>

      

    </div>
    <Footer/>

    {/* <?php include 'footer.php';?> */}
    
    </div>
  )
}

export default Edit_channel_integrate
