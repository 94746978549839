import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import moment from 'react-moment';
import download from 'downloadjs'
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import Footer from '../Footer';

const Customer_bank_new = () => {
    let navigate = useNavigate()
    let userDetail = localStorage.getItem('ship_rocket_user');
    console.log("userDetail====", userDetail);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const[userstate , setUserState] = React.useState({})
    const[companystate , setcompanyState] = React.useState({})
    const [formData, setFormData] = React.useState({
        accountname: '',
        accountNumber: '',
        accountifsc: '',
        cancelchequeimage: null,
      
      });
      const [errors, setErrors] = React.useState({});
    const [btnstate, setbtnstate] = React.useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({})
      };
    
      const handleFileChange = (e, type) => {
        setFormData({ ...formData, [type]: e.target.files[0] });
        setErrors({})
      };
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    });
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setbtnstate(true)
    let errors = {};
    let isValid = true;

    if (!formData.accountname) {
      errors.accountname = 'Account Name is required';
      isValid = false;
    }

    if (!formData.accountNumber) {
      errors.accountNumber = 'Account Number is required';
      isValid = false;
    }

    if (!formData.accountifsc) {
      errors.accountifsc = 'IFSC Code is required';
      isValid = false;
    }

    if (!formData.cancelchequeimage) {
      errors.cancelchequeimage = 'Cancel Cheque Image is required';
      isValid = false;
    }

    

    if (!isValid) {
      setErrors(errors);
      setbtnstate(false)

      return;
    }
        const data = new FormData();
        
        data.append('customer_id', userData.customer_id);

        data.append('accountname', formData.accountname);
        data.append('accountNumber', formData.accountNumber);
        data.append('accountifsc', formData.accountifsc);
        data.append('cancelchequeimage', formData.cancelchequeimage);
       
        for (let [key, value] of data.entries()) {
            console.log(key, value);
          }

        try {
            let full_api = config.apiUrl + `/user/add_customer_bank_details`;
            axios.post(full_api, data, { headers: config.headers }).then((res) => {
                if(res){
                    Toast.fire({
                        background:"#001b72",
                        type: 'success',
                        title: res.data.message ,
                        color:"white"
                      });
                    //   navigate('/customer/dashboard')
                    user()
      setbtnstate(false)

                 }
                 else{
                    Toast.fire({
                        background:"#c2200a",
                        type: 'success',
                        title: "Something Went Wrong" ,
                        color:"white"
                      });
      setbtnstate(false)

                    }
            })
            // Handle success or redirect user
          } catch (error) {
            console.error('Error:', error);
      setbtnstate(false)

            // Handle error
          }
      }
      React.useEffect(()=>{
        user()
     },[])
    const user=()=>{
        let dataToSend = {
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/find_customer_bank_detail';
    //  console.log("headers =========> ", config.headers);
      axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        console.log("res ===" , res.data.output)
        setUserState(res.data.customer)
        setcompanyState(res.data.company)
       
        setFormData({...formData ,accountname:res.data.output.account_name ,accountNumber:res.data.output.bankAccount , accountifsc:res.data.output.ifsc , cancelchequeimage:res.data.output.cancelchequeimage   })
        // console.log("response ===" , response)
        //   const obscuredAadhar = obscureAadharNumber(res.data.ouptut.aadhaar_number);
        // console.log("obscuredAadhar == " , obscuredAadhar)
        // setAadharNumber(obscuredAadhar);
      
          })
          .catch((error) => {
              console.log(error);
          });    
   }
  return (
    <div>
      <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
           
            <Header/>

            <section class="py-3">
              <div class="container customer_kyc_bx">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10">
                        <div class="">

                            <div>
                                {userstate.aadhar_kyc==true?
                                <div class="alert alert-success">
                                    <strong>Verified!</strong> Your Bank details has been verified already.
                                </div>:
                                <div class="alert alert-warning">
                                    <strong>Pending!</strong> Your Bank details are under review. Our team will update you soon.
                                </div>
}
                            </div>
                            <div class="mb-3">
                                <h2>Verify your bank details</h2>
                                <h6 class="">Requires a valid Bank details to tranfer the payments into your account</h6>
                            </div>
        
                            <div class="card mb-3">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="email" class="form-label">Account Holder Name:</label>
                                        <input type="email" class="form-control" id="email" placeholder="Enter Account Holder Name" name="accountname"
                        value={formData.accountname}
                        onChange={handleChange}/>
                           {errors.accountname && <div className="text-danger mt-2">{errors.accountname}</div>}
                        </div>
                     
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="email" class="form-label">Account Number:</label>
                                        <input type="email" class="form-control" id="email" placeholder="Enter Account Number" name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleChange}/>
                          {errors.accountNumber && <div className="text-danger mt-2">{errors.accountNumber}</div>}
                        </div>
                      
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="email" class="form-label">Branch IFSC Code:</label>
                                        <input type="email" class="form-control" id="email" placeholder="Enter Branch IFSC Code" name="accountifsc"
                        value={formData.accountifsc}
                        onChange={handleChange}/>
                           {errors.accountifsc && <div className="text-danger mt-2">{errors.accountifsc}</div>}
                        </div>
                     
                                </div>
                              </div>
                            </div>
        
                            <div class="card mb-3">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="upload_bx">
                                            <h4>Cancel Cheque</h4>
                                            <small class="mb-3">Upload Cancel Cheque</small>
                                            <img src={formData.cancelchequeimage} class="img-fluid img-thumbnail" />
                                            <div class="mt-3">
                                            <input
                                className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'cancelchequeimage')}/>
                             {errors.cancelchequeimage && <div className="text-danger mt-2">{errors.cancelchequeimage}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div> 
                        {userstate.aadhar_kyc==true?"":
                        <div class="py-3">
                             {btnstate==true?<div className='' style={{marginLeft:"60px"}}><ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={30}/></div>:<a onClick={(e)=>handleSubmit(e)} class="btn btn-primary">Update</a>}
                        </div>
}
                    </div>
                </div>     
              </div>
            </section>
        
    </div>
    <Footer/>
    </div>
  )
}

export default Customer_bank_new