import React from 'react'

import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Select from 'react-select';
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';

import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import Moment from 'react-moment';
import download from 'downloadjs'
import Header from './Header';
import Left_menu from './Left_menu';
import { useNetworkState } from 'react-use';
import { CornerUpLeft } from 'lucide-react';
import Footer from '../Footer';
import moment from 'moment';

const Pickup_request = () => {
    const getFormattedDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    const[state,setState]=React.useState([])
    const[filters,setfilters]=React.useState({request_id:"",pickup_from_date:moment().subtract(30, 'days').format('YYYY-MM-DD') ,pickup_to_date: moment().format('YYYY-MM-DD')})
   

    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    
 
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
React.useEffect(()=>{
    pickup_detail()
},[])
   
        const pickup_detail = ()=>{
        let url = config.apiUrl + '/shipment/pickup_request_details';
        let sendData = {  customer_id: userData.customer_id };
        if(filters.pickup_from_date!="" &&filters.pickup_to_date!="" ){
            sendData.pickup_from_date  = filters.pickup_from_date
            sendData.pickup_to_date  = filters.pickup_to_date

        }
        if(filters.request_id!=""){
            sendData.request_id = filters.request_id
        }
        // console.log("bb", sendData);
        axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
            
                // console.log("sasdfdsa")
                setState(res.data.output)
             
          

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           
        })
       }

       const handleChange=(e)=>{
        setfilters({...filters,[e.target.name]:e.target.value})
       }
    
  return (

<div >
<Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
    
          
    <div class="row mb-2">
        <div class="col-12 col-md">
            <div class="d-flex">
              
                <div class="">
                    <h2>Pickup Request</h2>
                </div>
            </div>
        </div>
    
    </div>
    <div class="row mb-3">
                <div class="col-12 col-md">
                    <div class="row g-1">
                    <div class="col-12 col-md-3">
                            <div class="input-group input-group-sm mb-3">
                            
                                <input type="text" class="form-control w-50" name="request_id" placeholder="Enter Request ID" onChange={(e)=>handleChange(e)} />
                               
                            </div>
                        </div>
                       
                        <div class="col-6 col-md-3 ms-3">
                        <input type="date" class="form-control form-control-sm" id="text" placeholder="Enter details name=" name="pickup_from_date" value={filters.pickup_from_date} onChange={(e)=>handleChange(e)} 
                        />
                         <div class="col-6 col-md-2">
                        <input type="date" class="form-control form-control-sm" id="text" placeholder="Enter details name=" name="pickup_to_date" value={filters.pickup_to_date}  onChange={(e)=>handleChange(e)}/>
                        </div>
                        </div>
                       
                        <div class="col-6 col-md-2 ms-3">
                        <button class="btn btn-outline-primary btn-sm" onClick={(e)=>pickup_detail()} >Search</button>
                        </div>
                     
                      
                      
                    </div>
                </div>
            </div>
    
    <div class="content-wrapper-data">
        <div class="card border-0">
          <div class="card-body">
                <div class="table-data table-responsive">
                    <table class="table table-hover table-responsive">
                        <thead>
                        <tr class="table-light">
                            <th>Request ID</th>
                            <th>Pickup Point</th>
                            <th>Pickup Date/Time</th>
                            <th>Package Count</th>
                            <th>Delivery Partner</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.map((sub)=>(
                         <tr class="ship-now-container">
                            <td>
                                <h3><a href="#">{sub.pickup_id}</a></h3>
                             </td>
                            <td>
                                <h4>{sub.warehouse_id?sub.warehouse_id:sub.warehouse_name}</h4>
                            </td>
                            <td>
                            <h4>{sub.pickup_date}<br/>
                               <small>{sub.pickup_time}</small></h4>
                            </td>
                            <td>
                                <h4>{sub.package_count}</h4>
                            </td>
                            <td class="channel_logo">
                                <h4>{sub.carrier_name}</h4>
                            </td>
                            
                           
                        </tr>
                        ))} 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    

    


    
</div>
{/* <!-- Main content area END --> */}
<Footer/>
</div>
  )
}

export default Pickup_request
