import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import TablePagination from '@mui/material/TablePagination';

import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import Left_menu from "./Left_menu";
import Header from "./Header";
import config from "../../config"
import Footer from "../Footer";


const Add_staff = () => {
    let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search = params.get('search')
   
  
    const [newtModalState, setNewModalState] = useState({full_name: "", email: "", phone: "",password:"",shipments:false,orders:false,settings:false,billing:false,support:false,reports:false,tools:false, warehouse:false,weight_discrepancy:false,staff:false, show: false , other:false });
    const [isLoading, setIsLoading] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })

   

  
   
    const inputHandleChange = (e) => {
        // console.log(e.target.name);
        // console.log(e.target.value);
        if (e.target.type == "file") {
            setNewModalState({ ...newtModalState, [e.target.name]: e.target.files });
        }else if(e.target.type=="checkbox"){
            if (e.target.checked == true) {
                setNewModalState({...newtModalState , [e.target.name]:true })
        } 
       
        else{
            setNewModalState({...newtModalState , [e.target.name]:false })
        }
    }
        else {
            setNewModalState({ ...newtModalState, [e.target.name]: e.target.value });
        }
    }
  
   

    const newUserSave = () => {
        console.log(newtModalState)
        if (newtModalState.full_name!=undefined && newtModalState.full_name != "" && newtModalState.email != undefined &&newtModalState.email != "" &&newtModalState.phone != undefined && newtModalState.phone != "" && newtModalState.password != undefined&& newtModalState.password != "") {
            setIsLoading(true)
            let full_api = config.apiUrl + `/user/add_staff`;
            
            let bodyData = {newtModalState};
            console.log("bb", bodyData);
            axios.post(full_api, bodyData, {
                headers: config.headers
            }).then((res) => {
                 setIsLoading(false)
                console.log("res ", res);
                if (res.data.status == true) {
                    navigate('/customer/staff')
                      
                    
                        Toast.fire({
                            background: "#001b72",
                            type: 'success',
                            title: res.data.message,
                            color: "white"
                        });
                   
                } else {
                    Toast.fire({
                        background: "#c2200a",
                        type: 'error',
                        title: res.data.message,
                        color: "white"
                    });
                }
              
            }).catch((e) => {
                setIsLoading(false)
                Toast.fire({
                    background: "#c2200a",
                    type: 'error',
                    title: "Something Went Wrong",
                    color: "white"
                });
            });

        } else {
            Toast.fire({
                background: "#c2200a",
                type: 'error',
                title: "Please fill all data.",
                color: "white"
            });
        }
    }

 


    return (
        <div>
              <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
               <div className="container">
                <div>
            
               
                <div class="row mb-2">
                <div class="col-12 col-md">
                    <div class="d-flex">
                       
                        <div class="">
                            <h2>Add Staff</h2>
                        </div>
                    </div>
                </div>
                
            </div>
              
              
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label for="email">Full name</label>
                            <input className="form-control" id="first_name" type="text" placeholder="Enter name" name="full_name" onChange={(e) => inputHandleChange(e)} required />
                          
                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Email</label>
                            <input className="form-control" id="first_name" type="email" placeholder="Enter Email" name="email" onChange={(e) => inputHandleChange(e)} required  autoComplete="off"/>

                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Password</label>
                            <input className="form-control" id="first_name" type="password" placeholder="Enter Password" name="password" onChange={(e) => inputHandleChange(e)} required autoComplete="off"/>

                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Phone</label>
                            <input className="form-control" id="first_name" type="Text" name="phone" placeholder="Enter phone" onChange={(e) => inputHandleChange(e)} />
                        </div>
                       
                        <div className="col-md-12 mb-3">
                            <label for="email" className="mb-2">Set Privilege</label>
                            <div className="row mb-2">
                            <div className="col-md-3">
                         
                             <input class="form-check-input " type="checkbox" name="shipments" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.shipments==true}/>
                             <small for="email" className="ms-2">Manage Shipments</small> 
                             </div>
                             <div className="col-md-3">
                         
                             <input class="form-check-input " type="checkbox" name="orders" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.orders==true}/>
                             <small for="email" className="ms-2">Manage Orders</small> 
                             </div>
                             <div className="col-md-3">
                           
                           <input class="form-check-input " type="checkbox" name="settings" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.settings==true}/>
                           <small for="email" className="ms-2">Manage Settings</small>
                           </div>
                           <div className="col-md-3">
                          
                          <input class="form-check-input " type="checkbox" name="warehouse" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.warehouse==true}/>
                          <small for="email" className="ms-2">Manage Warehouse</small> 
                          </div>

                            </div>
                          
                            <div className="row mb-2">
                            <div className="col-md-3">
                           
                             <input class="form-check-input " type="checkbox" name="weight_discrepancy" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.weight_discrepancy==true}/>
                             <small for="email" className="ms-2">Manage Weight Discrepancy</small> 
                             </div>
                             <div className="col-md-3">
                          
                             <input class="form-check-input " type="checkbox" name="tools" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.tools==true} />
                             <small for="email" className="ms-2">Manage Tools</small> 
                             </div>
                             <div className="col-md-3">
                          
                          <input class="form-check-input " type="checkbox" name="billing" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.billing==true}/>
                          <small for="email" className="ms-2">Manage Billing</small> 
                          </div>
                          <div className="col-md-3">
                           
                           <input class="form-check-input " type="checkbox" name="support" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.support==true}/>
                           <small for="email" className="ms-2">Manage Support</small> 
                           </div>

                            </div>
                            <div className="row">
                           
                           
                             <div className="col-md-4">
                           
                             <input class="form-check-input " type="checkbox" name="reports" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.reports==true}/>
                             <small for="email" className="ms-2">Manage Reports</small> 
                             </div>
                             <div className="col-md-4">
                           
                           <input class="form-check-input " type="checkbox" name="other" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.other==true}/>
                           <small for="email" className="ms-2">Manage Others</small> 
                           </div>
                             
                            </div>
                         
                        </div>
                    </div>
               
                  
                    <button className="btn btn-primary mt-2"
                        onClick={() =>
                            newUserSave()
                        }
                        >
                        Save
                    </button>
                
                </div>
               </div>
                  

                 

                </div>
                <Footer/>
        </div>
    )
}

export default Add_staff
