import React from 'react'
import Left_menu from './Left_menu'

import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';
import Modal from 'react-bootstrap/Modal';

const Header = () => {
    let navigate = useNavigate()
     const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const [userState, setUserState] = React.useState({ full_name: "" });
  const [trackstate, settrackState] = React.useState({ awb: "" });
  const[userstate , setUser] = React.useState({})
  const[searchstate,setsearch]= React.useState({search:""})
  const[modals,setModals] = React.useState({show: false})

  let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  React.useEffect(()=>{
    get_wallet_balance()
    dimension_detail()
    // user()

  },[])
//   const user=()=>{
//     let dataToSend = {
// };

//   // console.log("datatoSend",dataToSend)
//   let url = config.apiUrl + '/user/find_customer_detail';
//  // console.log("headers =========> ", config.headers);
//   axios.post(url, dataToSend, { headers: config.headers })
//   .then((res) => {
//     // console.log("res ===" , res.data.output)
//     setUser(res.data.output)
//     let response  = res.data.ouptut
//     // // console.log("response ===" , response)
//     //   const obscuredAadhar = obscureAadharNumber(res.data.ouptut.aadhaar_number);
//     // // console.log("obscuredAadhar == " , obscuredAadhar)
//     // setAadharNumber(obscuredAadhar);
//       })
//       .catch((error) => {
//           // console.log(error);
//       });    
// }

    const get_wallet_balance=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/get_customer_wallet';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
           setbalanceState({total_balance:res.data.find_customer_wallet})
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"#c2200a",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"#c2200a",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
       const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
      const logOutButton = () =>{
        // console.log("userDetail ===>   ");
      
        let full_api = config.apiUrl + `/user/user_logout`;
          let sendData = {customer_id:userData.customer_id};
          // console.log("bb", sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
              // console.log("res ", res);
              if (res.data.status==true) {
                localStorage.removeItem('ship_rocket_user');
                userDetail = null;
                setUserState({full_name:""})
                navigate('/customer/login')
              }else {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                Toast.fire({
                  background:"#c2200a",
                  type: 'unsuccess',
                  title: res.data.message ,
                  color:"white"
                });
                 
              }
      
          }).catch((e) => {
      
      
          });
        
      
      }

      const handlechnage=(e)=>{
       settrackState({...trackstate , awb:e.target.value})
      }

      const searchchange =(e)=>{
        setsearch({search:e.target.value})
      }

      const shipmentpage = ()=>{
        window.location.href = `/customer/shipments?number=${searchstate.search}`;
      }
  return (
//     <div>
//        <header class="shadow-sm">
//             <div class="container">
//                 <nav class="navbar navbar-expand-sm navbar_vendor">
//                     <div class="container-fluid">
//                     {/* <!-- <a class="navbar-brand" href="javascript:void(0)">Shiport.in</a> --> */}
//                     <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
//                         <span class="navbar-toggler-icon"></span>
//                     </button>
//                     <div class="collapse navbar-collapse" id="mynavbar">
//                         <ul class="navbar-nav me-auto">
//                         {/* <!-- <li class="nav-item">
//                             <a class="nav-link" href="">Home</a>
//                         </li> --> */}
//                         </ul>
//                         <div class="d-flex">
//                             <ul class="navbar-nav ">
//                                 <li class="nav-item dropdown">
//                                 <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Quick Action</a>
//                                     <ul class="dropdown-menu">
//                                         <li><a class="dropdown-item" style={{cursor:"pointer"}} onClick={()=>navigate({
//             pathname: '/customer/create_order_1',
//             search: `value=0`,
//           })}>Create Order</a></li>
//                                         <li><a class="dropdown-item" style={{cursor:"pointer"}} href="/customer/warehouse">Create Warehouse</a></li>
//                                         <li><a class="dropdown-item" style={{cursor:"pointer"}} href="/customer/rate_calculator">Rate Calculator</a></li>
//                                         <li><a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#track_order">Track Shipment</a></li>
                                        
//                                     </ul>
//                                 </li>
//                                 <li class="nav-item">
//                                 <a class="nav-link " href=""><i class="fa fa-window-maximize" aria-hidden="true"></i> Available Credit Limit  <span class="text-muted">&#8377;{balancestate.total_balance.map((sub)=>(
//                        sub.wallet_balance>0 && sub.credit_limit?  parseFloat(sub.credit_limit).toFixed(2):!sub.credit_limit?0:parseFloat(sub.wallet_balance+sub.credit_limit).toFixed(2)))}</span> </a>
//                                 </li>
//                                 <li class="nav-item">
//                                 <a class="nav-link " href=""><i class="fa fa-window-maximize" aria-hidden="true"></i> Wallet <span class="text-muted">&#8377;{balancestate.total_balance.map((sub)=>(
//                        parseFloat(sub.wallet_balance).toFixed(2)))}</span> </a>
//                                 </li>
//                                 <li class="nav-item">
//                                 <a class="nav-link" href=""><i class="fa fa-bell" aria-hidden="true"></i></a>
//                                 </li>
//                                 <li class="nav-item dropdown top_user_nav_dropdown">
//                                 <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false"><i class="fa fa-cog" aria-hidden="true"></i></a>
//                                     <ul class="dropdown-menu dropdown-menu-lg-end top_user_nav_dropdown">
//                                         <li>
//                                             <a class="dropdown-item" style={{cursor:"pointer"}} href="#">
//                                                 <span class="pe-3"><i class="fa fa-user" aria-hidden="true"></i></span> {userData.full_name}
//                                             </a>
//                                         </li>
//                                         <li>
//                                         <a class="dropdown-item customer_kyc" href="/customer/customer_kyc">
                                          
//                                                 <span class="pe-3"><i class="fa fa-id-card" aria-hidden="true"></i></span> 
//                                                 Customer KYC
//                                                 {/* {userstate.aadhar_kyc!=undefined && userstate.aadhar_kyc==true? 
//                                               <span class="badge_verified">Verified</span>
//                                                  : <span class="badge_unverified">Unverified</span>} */}
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a class="dropdown-item" style={{cursor:"pointer"}} href='#'>
//                                                 <span class="pe-3"><i class="fa fa-lock" aria-hidden="true"></i></span> Change Password
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a class="dropdown-item" style={{cursor:"pointer"}} onClick={() => logOutButton()}>
//                                                 <span class="pe-3"><i class="fa fa-sign-out" aria-hidden="true"></i></span> Logout
//                                             </a>
//                                         </li>
//                                     </ul>
//                                 </li>
                                
//                             </ul>
//                         </div>
//                     </div>
//                     </div>
//                 </nav>
//             </div>
//         </header>
//         <div class="modal" id="track_order">
//   <div class="modal-dialog modal-lg">
//     <div class="modal-content">
//       <div class="modal-header">
//         <h4 class="modal-title">Track Shipment</h4>
//         <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
//       </div>
//       <div class="modal-body text-center p-5">
//         <input type="text" class="form-control form-control-lg" id="text" name="awb" placeholder="Enter AWB number for tracking" onChange={(e)=>handlechnage(e)} />
//         <div class="d-grid mt-3">
//           <a  href={"/tracking/"+ trackstate.awb} class="btn btn-primary btn-block">Track Now</a>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//     </div>
<div>
{/* <div class="modal" id="track_order">
   <div class="modal-dialog modal-lg">
    <div class="modal-content">
       <div class="modal-header">
         <h4 class="modal-title">Track Shipment</h4>
         <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
       </div>
       <div class="modal-body text-center p-5">
         <input type="text" class="form-control form-control-lg" id="text" name="awb" placeholder="Enter AWB number for tracking" onChange={(e)=>handlechnage(e)} />
         <div class="d-grid mt-3">
           <a  href={"/tracking/"+ trackstate.awb} class="btn btn-primary btn-block">Track Now</a>
         </div>
       </div>
     </div>
   </div>
   </div> */}
   
                {/* <ul class="nav justify-content-end header-nav">
                  <form class="d-flex me-auto ">
        <div class="search-box">
            <div class="search-icon">
                <i class="fa fa-search"></i> 
            </div>
            <input type="text" class="search-input" placeholder="Search packages..."/>
        </div>
      </form>
                    <li class="nav-item border shadow-lg">
                      <div class="header-nav-dropdown ">
                        <i class="material-icons-outlined">account_balance_wallet</i>

                        <ul class="dropdown-menu shadow-sm">
                            <li><a class="dropdown-item" href="#">Wallet Balance: 
                                <h4>₹{balancestate.total_balance.map((sub)=>(
                        parseFloat(sub.wallet_balance).toFixed(2)))}</h4>
                            </a></li>
                            <li><hr class="dropdown-divider"/></li>
                            <li><a class="dropdown-item" href="#">Available Credit Limit: 
                            <h4>₹{balancestate.total_balance.map((sub)=>(
                        sub.wallet_balance>0 && sub.credit_limit?  parseFloat(sub.credit_limit).toFixed(2):!sub.credit_limit?0:parseFloat(sub.wallet_balance+sub.credit_limit).toFixed(2)))}</h4>
                            </a></li>
                            <li><hr class="dropdown-divider"/></li>
                          
                        </ul>
                       </div>
                    </li>
                    <li class="nav-item border shadow-lg">
                      <div class="header-nav-dropdown ">
                        <i class="material-icons-outlined">notifications</i>

                        <ul class="dropdown-menu shadow-sm">
                          
                            <li><a class="dropdown-item" onClick={() => logOutButton()}>Logout</a></li>
                        </ul>
                       </div>
                    </li>
                    <li class="nav-item border shadow-lg">
                      <div class="header-nav-dropdown-apps ">
                        <i class="material-icons-outlined">apps</i>

                        <ul class="dropdown-menu shadow-sm">
                            
                            <li>
                                <a class="dropdown-item"  data-bs-toggle="tooltip" data-bs-placement="top" title="Create Order" onClick={()=>navigate({
            pathname: '/customer/create_order_1',
             search: `value=0`,
           })}><i class="material-icons">view_in_ar</i></a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/customer/rate_calculator" data-bs-toggle="tooltip" data-bs-placement="top" title="Rate Calculator"><i class="material-icons-outlined">calculate</i></a>
                            </li>
                            <li>
                                <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#track_order" data-bs-placement="top" title="Track Shipment"><i class="material-icons-outlined">search</i></a>
                            </li>
                         
                            <li>
                                <a class="dropdown-item" href="/customer/reports" data-bs-toggle="tooltip" data-bs-placement="top" title="Reports"><i class="material-icons-outlined">assignment</i></a>
                            </li>
                        </ul>
                       </div>
                    </li>
                </ul> */}
            {/* <hr/> */}

            <header>
            <Modal show={modals.show} onHide={()=>setModals({show:false})} >
                                    <Modal.Header closeButton>
                                      <Modal.Title>  
                                      {/* <div class="modal-header"> */}
         {/* <h4 class="modal-title"> */}
          Track Shipment
          {/* </h4> */}
        
       {/* </div> */}
       </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <div class="modal-body text-center p-5">
         <input type="text" class="form-control form-control-lg" id="text" name="awb" placeholder="Enter AWB number for tracking" onChange={(e)=>handlechnage(e)} />
         <div class="d-grid mt-3">
           <a  href={"/tracking/"+ trackstate.awb} class="btn btn-primary btn-block">Track Now</a>
         </div>
       </div>
                                    </Modal.Body>
                                  
                                  
                                  </Modal>
{/* <div class="modal" id="track_order">
   <div class="modal-dialog modal-lg">
    <div class="modal-content">
       <div class="modal-header">
         <h4 class="modal-title">Track Shipment</h4>
         <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
       </div>
       <div class="modal-body text-center p-5">
         <input type="text" class="form-control form-control-lg" id="text" name="awb" placeholder="Enter AWB number for tracking" onChange={(e)=>handlechnage(e)} />
         <div class="d-grid mt-3">
           <a  href={"/tracking/"+ trackstate.awb} class="btn btn-primary btn-block">Track Now</a>
         </div>
       </div>
     </div>
   </div>
   </div> */}
<nav class="navbar navbar-expand-sm ">
  <div class="container-fluid">
    
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div className='display_logo'>
   <a href='/customer/new_dashboard' style={{color:"#082D79"}}> <h4 >Kourierwale</h4></a>
    </div>
    <div class="collapse navbar-collapse mb-3" id="mynavbar">
     
      <form class="d-flex me-auto ">
        <div class="search-box">
            <div class="search-icon">
                <i class="fa fa-search" onClick={shipmentpage}></i> 
            </div>
            <input type="text" class="search-input" placeholder="Search shipment..." onChange={(e)=>searchchange(e)}  onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          shipmentpage();
        }
      }}/>
        </div>
      </form>
      
      <div class="d-flex headericon">
        <button className='btn btn-primary btn-sm me-4' onClick={()=>navigate('/customer/wallet_recharge')}>Recharge</button>
        <ul class="nav justify-content-end header-nav">
                    <li class="nav-item border shadow-lg">
                      <div class="header-nav-dropdown ">
                        <i class="material-icons-outlined">account_balance_wallet</i>

                        <ul class="dropdown-menu shadow-sm">
                            <li><a class="dropdown-item" href="#">Wallet Balance: 
                                <h4>₹{balancestate.total_balance.map((sub)=>(
                        parseFloat(sub.wallet_balance).toFixed(2)))}</h4>
                            </a></li>
                            <li><hr class="dropdown-divider"/></li>
                            <li><a class="dropdown-item" href="#">Available Credit Limit: 
                            <h4>₹{balancestate.total_balance.map((sub)=>(
                        sub.wallet_balance>0 && sub.credit_limit?  parseFloat(sub.credit_limit).toFixed(2):!sub.credit_limit?0:parseFloat(sub.wallet_balance+sub.credit_limit).toFixed(2)))}</h4>
                            </a></li>
                            <li><hr class="dropdown-divider"/></li>
                          
                        </ul>
                       </div>
                    </li>
                    <li class="nav-item border shadow-lg">
                      <div class="header-nav-dropdown ">
                        <i class="material-icons-outlined">person</i>

                        <ul class="dropdown-menu shadow-sm">
                            <li><a class="dropdown-item" href="/customer/settings_main">My Account</a></li>
                            {/* <li><a class="dropdown-item" href="#">Edit Profile</a></li> */}
                            <li><a class="dropdown-item" >{userData.full_name}</a></li>
                            <li><hr class="dropdown-divider"/></li>
                           
                            <li><a class="dropdown-item" onClick={() => logOutButton()}>Logout</a></li>
                        </ul>
                       </div>
                    </li>
                    <li class="nav-item border shadow-lg">
                      <div class="header-nav-dropdown-apps ">
                        <i class="material-icons-outlined">apps</i>

                        <ul class="dropdown-menu shadow-sm">
                        <li>
                                <a class="dropdown-item"  data-bs-toggle="tooltip" data-bs-placement="top" title="Create Order" onClick={()=>navigate({
            pathname: '/customer/create_order_1',
             search: `value=0`,
           })}><i class="material-icons">view_in_ar</i></a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/customer/rate_calculator" data-bs-toggle="tooltip" data-bs-placement="top" title="Rate Calculator"><i class="material-icons-outlined">calculate</i></a>
                            </li>
                            <li>
                                <a class="dropdown-item" onClick={()=>setModals({show:true})} title="Track Shipment"><i class="material-icons-outlined">search</i></a>
                            </li>
                         
                            <li>
                                <a class="dropdown-item" href="/customer/reports" data-bs-toggle="tooltip" data-bs-placement="top" title="Reports"><i class="material-icons-outlined">assignment</i></a>
                            </li>
                            <li>
                                <a class="dropdown-item" onClick={() => logOutButton()} data-bs-toggle="tooltip" data-bs-placement="top" title="Logout"> <i class="bx bx-log-out" ></i></a>
                            </li>
                        </ul>
                       </div>
                    </li>
                </ul>
      </div>
    </div>
  </div>
</nav>



                
            <hr/>
            </header>
            </div>
  )
}

export default Header
